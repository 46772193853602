import ButtonClose from "../buttons/ButtonClose";
import ModulContainer from "./ModulContainer";

type ModuleBigFotoProps = {
  isShowBigAvatar: () => void;
  urlFoto: string;
};

export default function ModuleBigFoto({
  isShowBigAvatar,
  urlFoto,
}: ModuleBigFotoProps) {
  return (
    <ModulContainer clickOverly={isShowBigAvatar}>
      <div className="module-foto">
        <img className="module-foto__img" src={urlFoto} alt="аватар" />
        <ButtonClose funcClose={isShowBigAvatar} />
      </div>
    </ModulContainer>
  );
}
