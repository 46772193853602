type BottonSubmitProps = {
  text: string;
};

export default function BottonSubmit({ text }: BottonSubmitProps) {
  return (
    <button className="botton-submit" type="submit">
      {text}
    </button>
  );
}
