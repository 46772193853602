import { useNavigate } from "react-router-dom";
import Clue from "./Clue";

export default function Header() {
  const navigate = useNavigate();

  return (
    <header className="header">
      <img className="header__logo" src="./images/logo.png" alt="логотип"></img>
      <Clue textClue="Открыть сумку с товаром">
        <img
          onFocus={() => {
            document.addEventListener(
              "keydown",
              (evt: KeyboardEvent) => {
                if (evt.code === "Enter") {
                  navigate("/bag");
                }
              },
              {
                once: true,
              }
            );
          }}
          tabIndex={0}
          onClick={() => navigate("/bag")}
          className="header__shoping"
          src="./images/bag.jpg"
          alt="Пакет с товаром "
        ></img>
      </Clue>
    </header>
  );
}
