import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BagProduct, CardProduct } from '../../types/typeProduts';
import { SortEnum } from '../../types/sortEnum';

interface ProductState {
  arrProduct: CardProduct[];
  sortProduct: CardProduct[];
  sort: SortEnum;
  vulueSearch: string;
  productBag: BagProduct[];
}

const initialState: ProductState = {
  arrProduct: [],
  sortProduct: [],
  sort: SortEnum.SORT_ALL,
  vulueSearch: '',
  productBag: [],
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetProductBag(state) {
      state.productBag = [];
    },
    setProductBag(state, action: PayloadAction<BagProduct[]>) {
      state.productBag = action.payload;
    },
    addProductBag(state, action: PayloadAction<BagProduct>) {
      if (state.productBag.find((elem) => elem._id === action.payload._id)) {
        const indexProduct = state.productBag.findIndex(
          (elem) => elem._id === action.payload._id
        );

        state.productBag.splice(indexProduct, 1);
      } else {
        state.productBag.push(action.payload);
      }
      localStorage.setItem('bag', JSON.stringify(state.productBag));
    },
    setValueSearch(state, action: PayloadAction<string>) {
      state.vulueSearch = action.payload;
    },
    setSort(state, action: PayloadAction<SortEnum>) {
      state.sort = action.payload;
    },
    setProduct(state, action: PayloadAction<CardProduct[]>) {
      state.arrProduct = action.payload;
    },
    setSortProduct(state, action: PayloadAction<SortEnum>) {
      const arrSearch = state.arrProduct.filter((elem) =>
        elem.title?.toLowerCase().includes(state.vulueSearch.toLowerCase())
      );

      if (action.payload === SortEnum.SORT_ALL) {
        state.sortProduct = arrSearch;
        return;
      }

      const newErr = arrSearch.filter((elem) =>
        elem.gender?.find(
          (obj) => obj.checked === true && obj.value === action.payload
        )
      );
      state.sortProduct = newErr;
    },
    setSearchProduct(state, action: PayloadAction<CardProduct[]>) {
      state.sortProduct = action.payload;
    },
  },
});

export const selectProduct = (state: RootState) => state.product;
export const {
  setSortProduct,
  setSearchProduct,
  setProduct,
  setSort,
  setValueSearch,
  addProductBag,
  setProductBag,
  resetProductBag,
} = productSlice.actions;
export default productSlice.reducer;
