type ParamsInputPhone = {
  valuePhone: string;
  setValuePhone: React.Dispatch<React.SetStateAction<string>>;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  invalid: boolean | undefined;
  valid: boolean;
};

export default function InputPhone({
  valuePhone,
  setValuePhone,
  setValid,
  invalid,
  valid,
}: ParamsInputPhone) {
  const checkValue = (
    evt: React.ChangeEvent<HTMLInputElement>,
    num: number
  ) => {
    if (
      evt.target.value.length === num &&
      (evt.nativeEvent as any).data != null
    )
      return true;
  };

  const changeValuePhone: React.ComponentProps<"input">["onChange"] = (evt) => {
    const data: string = (evt.nativeEvent as any).data;
    const regex = /[0-9]/;
    const result = regex.test(data);

    if (data != null) {
      if (!result) return;
    }

    if (data === " ") {
      console.log("stop");

      return;
    }

    if (evt.target.value.length === 3 && data === null) {
      setValuePhone("");
      return;
    }

    if (checkValue(evt, 1)) {
      setValuePhone(`+7 (${data}`);
      return;
    }
    if (checkValue(evt, 8)) {
      setValuePhone(`${valuePhone}) ${data}`);
      return;
    }

    if (checkValue(evt, 13)) {
      setValuePhone(`${valuePhone} ${data}`);
      return;
    }
    if (checkValue(evt, 16)) {
      setValuePhone(`${valuePhone} ${data}`);
      return;
    }
    setValuePhone(evt.target.value);

    setValid(evt.target.checkValidity());
  };

  return (
    <input
      className={
        invalid
          ? valid
            ? "input_state_valid"
            : "input_state_invalid-custom"
          : ""
      }
      name="phone"
      pattern="^((\+7)[\- ])?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$"
      value={valuePhone ?? ""}
      onChange={(evt) => changeValuePhone(evt)}
      required
      type="tel"
      placeholder="+7"
      maxLength={18}
      minLength={18}
    />
  );
}
