import React, { useState } from "react";
import { setSort, setSortProduct } from "../redax/slices/productSlice";
import { SortEnum } from "../types/sortEnum";
import { useDispatch } from "react-redux";
import Clue from "./Clue";
import ButtonClose from "./buttons/ButtonClose";

type CatalogProps = {
  menu: boolean;
  isMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Catalog({ menu, isMenu }: CatalogProps) {
  const [perfumeMenu, setPerfumeMenu] = useState(false);
  const [titleActive, isTitleActive] = useState(0);
  const dispatch = useDispatch();
  const listMenuPerfume: SortEnum[] = [
    SortEnum.SORT_ALL,
    SortEnum.SORT_WOMAN,
    SortEnum.SORT_MAN,
  ];

  const showSort = (title: SortEnum, i: number) => {
    dispatch(setSort(title));
    isTitleActive(i);
    dispatch(setSortProduct(title));
  };

  return (
    <section className={`catalog ${menu && "catalog__on"}`}>
      <ButtonClose funcClose={() => isMenu(false)} />
      <Clue textClue={"Сортировка"}>
        <button
          onClick={() => setPerfumeMenu(!perfumeMenu)}
          className={`catalog__box-title ${
            perfumeMenu ? "catalog__box-title__on" : "catalog__box-title__off"
          }`}
        >
          <h3 className="catalog__title">Парфюмерия</h3>
        </button>
      </Clue>

      {perfumeMenu && (
        <ul className="list-catalog">
          {listMenuPerfume.map((title, i) => (
            <li
              onFocus={(evt) => {
                evt.target.addEventListener(
                  "keydown",
                  (evt: KeyboardEvent) => {
                    if (evt.code === "Enter") {
                      showSort(title, i);
                    }
                  },
                  { once: true }
                );
              }}
              tabIndex={titleActive === i ? -1 : 0}
              key={title + i}
              className={
                titleActive === i
                  ? "list-catalog__title list-catalog__title_active"
                  : "list-catalog__title"
              }
              onClick={() => {
                if (titleActive === i) {
                  return;
                }

                showSort(title, i);
              }}
            >
              {title}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
