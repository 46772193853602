import { BottonSubmit, ErrorSpan } from "./index";

type FormProps = {
  handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
  textButton: string;
  children: (JSX.Element | string)[] | JSX.Element;
  formRef?: React.LegacyRef<HTMLFormElement> | undefined;
  textErr?: string;
};

export default function Form({
  handleSubmit,
  textButton,
  children,
  formRef,
  textErr,
}: FormProps) {
  return (
    <form
      ref={formRef}
      noValidate
      onSubmit={(evt) => handleSubmit(evt)}
      className="form"
    >
      {children}
      <BottonSubmit text={textButton} />
      <ErrorSpan text={textErr} />
    </form>
  );
}
