import { useSelector } from 'react-redux';
import { selectPopUpWindow } from '../redax/slices/popUpWindowSlice';

type PopUpWindowProps = {
  text: string;
};

export default function PopUpWindow({ text }: PopUpWindowProps) {
  const { stateWindow } = useSelector(selectPopUpWindow);

  return (
    <div className={`pop-up-window ${stateWindow && 'pop-up-window_state_on'}`}>
      <h1 className="pop-up-window__text">{text}</h1>
    </div>
  );
}
