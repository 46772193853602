import { useState } from "react";

import {
  PopUpWindow,
  Product,
  Catalog,
  Footer,
  Header,
} from "../components/index";

export default function Home() {
  const [menu, isMenu] = useState(false);
  return (
    <>
      <Header />
      <main className="main">
        <Catalog menu={menu} isMenu={isMenu} />
        <Product isMenu={isMenu} />
      </main>
      <Footer />
      <PopUpWindow text="Заказ успешно добавлен, наш менеджер скоро с вами свяжется." />
    </>
  );
}
