type ButtonProps = {
  buttonClick: () => void;
  text: string;
};

export default function Button({ buttonClick, text }: ButtonProps) {
  return (
    <button className="button-click" onClick={() => buttonClick()}>
      {text}
    </button>
  );
}
