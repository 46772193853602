type BlankPageProps = {
  text: string;
};

export default function BlankPage({ text }: BlankPageProps) {
  return (
    <div className="bag__not-order">
      <p className="bag__not-order-text">{text}</p>
    </div>
  );
}
