import { useEffect, useRef, useState } from "react";
import { geеСoordinatesClue } from "../utils/helpers";

type ClueProps = {
  textClue: string;
  children: JSX.Element | string | (JSX.Element | string)[];
  active?: boolean;
};

export default function Clue({ textClue, children, active = true }: ClueProps) {
  const refClue = useRef<HTMLSpanElement>(null);

  const [clue, toggleClue] = useState(false);
  const [topClue, setTopClue] = useState(0);
  const [leftClue, setLeftClue] = useState(0);
  let timeOutClue: NodeJS.Timeout;

  useEffect(() => {
    if (clue) {
      const { top, left } = geеСoordinatesClue(refClue, ".clue");

      if (top && left) {
        setTopClue(top);
        setLeftClue(left);
      }
    }
  }, [clue]);

  const showСlue = (evt: React.PointerEvent<HTMLDivElement>) => { 
    if (evt.pointerType === "mouse") {
      timeOutClue = setTimeout(() => toggleClue(true), 0);
    }
  };

  return (
    <div
      className="clue"
      onPointerEnter={(evt) => active && showСlue(evt)}
      onPointerLeave={() => {
        if (active) {
          clearTimeout(timeOutClue);
          toggleClue(false);
        }
      }}
    >
      {children}
      {clue && (
        <span
          className="clue__text"
          ref={refClue}
          style={{ top: topClue, left: leftClue }}
        >
          {textClue}
        </span>
      )}
    </div>
  );
}
