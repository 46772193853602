import { BagProduct } from "../types/typeProduts";
import { URL_SERVER } from "./Constants";

export type ProductApi = {
  baseUrl: string;
  baseHeaders: { "content-type": string };
};

class Product {
  readonly baseUrl: string;
  readonly baseHeaders: { "content-type": string };

  constructor({ baseUrl, baseHeaders }: ProductApi) {
    this.baseUrl = baseUrl;
    this.baseHeaders = baseHeaders;
  }

  public getAllProduct() {
    return fetch(`${this.baseUrl}/product`, {
      method: "GET",
      headers: this.baseHeaders,
    }).then(this._checkResponse);
  }

  public addOrderProduct(name: string, phone: string, products: BagProduct[]) {
    return fetch(`${this.baseUrl}/order`, {
      method: "POST",
      headers: this.baseHeaders,
      body: JSON.stringify({
        name,
        phone,
        products,
      }),
    }).then(this._checkResponse);
  }

  private _checkResponse = (res: Response) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((err) => Promise.reject(JSON.parse(err)));
  };
}

const product = new Product({
  baseUrl: `${URL_SERVER}/auth-not`,
  baseHeaders: {
    "content-type": "application/json",
  },
});

export { product };
