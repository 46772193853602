import ModulContainer from './ModulContainer';
import ModuleSearch from './ModuleSearch';

export default function ModulePreloader({ text }: { text: string }) {
  return (
    <ModulContainer>
      <div className="module-preloader">
        <p className="module-preloader__title">{text}</p>
        <ModuleSearch />
      </div>
    </ModulContainer>
  );
}
