type Сoordinates = {
  top: number | undefined;
  left: number | undefined;
};

const geеСoordinatesClue = (
  refClue: React.RefObject<HTMLSpanElement>,
  parentClue: string
): Сoordinates => {
  let top;
  let left;

  if (refClue && refClue.current) {
    const clue = refClue.current?.getBoundingClientRect();

    const parent = refClue.current
      ?.closest(parentClue)
      ?.getBoundingClientRect();

    const widthWindow = document.documentElement.clientWidth;

    if (parent) {
      if (parent.top + 5 > clue.height) {
        top = parent.top - clue.height - 5;
      } else {
        top = parent.top + parent.height + 5;
      }

      if (widthWindow - parent.left - parent.width - 5 < clue.width) {
        left = parent.left - clue.width - 5;
      } else {
        left = parent.left + parent.width + 5;
      }
    }

    return { top, left };
  }

  return { top, left };
};

export { geеСoordinatesClue };
