import { useEffect, useState } from "react";
import {
  selectProduct,
  setSortProduct,
  setSearchProduct,
  setProduct,
  setValueSearch,
  setProductBag,
} from "../redax/slices/productSlice";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { SortEnum } from "../types/sortEnum";
import { CardProduct } from "../types/typeProduts";
import { product } from "../utils/ProductApi";
import { ModuleSearch, ErrorSpan, ErrorBox } from "./index";
import BlankPage from "./BlankPage";

type ProductProps = {
  isMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Product({ isMenu }: ProductProps) {
  const windowHeight: number = window.innerHeight - 130;
  const dispatch = useDispatch();
  const { sortProduct, arrProduct, sort, productBag } =
    useSelector(selectProduct);

  const [valueSearch, isValueSeartch] = useState("");
  const [searchGetProduct, isSearchGetProduct] = useState(true);

  useEffect(() => {
    const bag = localStorage.getItem("bag");
    if (bag) {
      const bagPars = JSON.parse(bag);
      dispatch(setProductBag(bagPars));
    }
    product
      .getAllProduct()
      .then((res: CardProduct[]) => {
        dispatch(setProduct(res));
        dispatch(setSortProduct(SortEnum.SORT_ALL));
      })
      .catch((err) => console.log(err))
      .finally(() => isSearchGetProduct(false));
  }, []);

  let search: boolean = true;
  const searchProduct = (
    value: string,
    arr: CardProduct[],
    time: number,
    sort: SortEnum
  ): void => {
    //console.log(value);
    dispatch(setValueSearch(value));

    if (search) {
      setTimeout(() => {
        if (sort != SortEnum.SORT_ALL) {
          const sortErr = arr.filter((elem) =>
            elem.gender?.find(
              (obj) => obj.checked === true && obj.value === sort
            )
          );
          const newArr = sortErr.filter((elem) =>
            elem.title?.toLowerCase().includes(value.toLowerCase())
          );
          dispatch(setSearchProduct(newArr));
        } else {
          const newArr = arr.filter((elem) =>
            elem.title?.toLowerCase().includes(value.toLowerCase())
          );
          dispatch(setSearchProduct(newArr));
        }
        search = true;
      }, time);
      search = false;
    }
  };

  return (
    <section style={{ height: windowHeight }} className="product">
      <div className="product__header">
        <div className="product__menu-sort" onClick={() => isMenu(true)}></div>
        <input
          value={valueSearch}
          onChange={(evt) => {
            isValueSeartch(evt.target.value);
            searchProduct(evt.target.value, arrProduct, 800, sort);
          }}
          className="product__search input"
          placeholder="Поиск..."
        />
        <h2 className="product_title">Каталог</h2>
      </div>
      {searchGetProduct ? (
        <ModuleSearch />
      ) : sortProduct.length > 0 ? (
        <div className="product__conteiner-card">
          {sortProduct.map((obj, i) => {
            const bagfind = productBag.find((elem) => elem._id === obj._id);
            return (
              <Card {...obj} key={obj._id + i} bag={bagfind ? true : false} />
            );
          })}
        </div>
      ) : (
        <BlankPage text={"Товар отсутствует или не найден"} />
      )}
    </section>
  );
}
